<template>
    <v-btn @click="triggerFileInput" color="primary">Cargar archivo Excel</v-btn>
  
    <input ref="fileInput" type="file" @change="handleFileChange" style="display: none" />
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  import * as XLSX from 'xlsx';
  
  import { useUserStore } from '@/store/Users';
  const store = useUserStore();
  
  type TableData = string[][];
  
  // Referencias para manejar el input de archivo
  const fileInput = ref<HTMLInputElement | null>(null);
  
  // Datos de la tabla (se llenarán después de cargar el archivo)
  const tableData = ref<TableData>([]);
  
  // Encabezados de la tabla (se llenarán dinámicamente a partir de la primera fila del archivo)
  const headers = ref<string[]>([]);
  
  // Función para activar el input de archivo
  const triggerFileInput = () => {
    if (fileInput.value) {
      fileInput.value.click();
    }
  };
  
  // Función para manejar el cambio de archivo
  const handleFileChange = (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      readExcelFile(file);
    }
  };
  
  // Función para leer el archivo Excel
  const readExcelFile = (file: File) => {
    const reader = new FileReader();
  
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target?.result;
      if (data) {
        const workbook = XLSX.read(data, { type: 'array' });
  
        // Suponiendo que solo tenemos una hoja en el archivo
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  
        // Convertir la hoja a un formato JSON (de filas a columnas)
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
        // La primera fila contiene los encabezados
        headers.value = jsonData[0] as string[];
  
        // Las siguientes filas contienen los datos
        const rawData = jsonData.slice(1) as string[][];
  
        // Convertir cada fila de datos en un objeto usando los encabezados
        const finalResult = rawData.map(row => {
          return headers.value.reduce((obj, header, index) => {
            obj[header] = row[index] || ''; // Asigna el valor de la fila a la clave correspondiente
            return obj;
          }, {} as { [key: string]: string });
        });
  
        // Mostrar el resultado final en la consola (opcional)
        console.log(finalResult);
  
        // Guardar el resultado en el store
        store.setUsers(finalResult);
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  </script>
  
  <style scoped>
  /* Puedes personalizar los estilos si es necesario */
  </style>
  