<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn 
            size="small"
            v-bind="activatorProps"
            color="surface-variant"
            variant="flat"
            >Ver QR</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card>
            <v-card-text>
                <v-row justify="center">
                    <v-col cols="12">
                        <QrGenerator ref="qrGeneratorRef" :text="url" :nombre="nombre" :tel="tel" />
                    </v-col>
                </v-row>    
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                text="Enviar por whatsApp"
                @click="sendLinkWs"
                color="green"
                ></v-btn>
                <v-btn
                text="Cerrar"
                @click="isActive.value = false"
                ></v-btn>
                
            </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script setup lang="ts">
import QrGenerator from './QrGenerator.vue';
import { defineProps, ref, watch } from 'vue';

const qrGeneratorRef = ref(null);

  // Definir las props
const props = defineProps({
    url: {
        type: String,
        required: true,
    },
    nombre: {
        type: String,
        required: true,
    },
    tel: {
        type: Number,
        required: true,
    },
});
const sendLinkWs = () => {
    // Verificamos si el componente hijo está disponible y llamamos a la función
    if (qrGeneratorRef.value) {
        qrGeneratorRef.value.uploadAndGenerateLink(); // Llamamos a la función expuesta desde el hijo
    }
};
</script>