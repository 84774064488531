<template>
    <v-card>
        <v-card-title>Lista de clientes</v-card-title>
        
        <v-container>
            <v-row>
                <v-col cols="3">
                    <UploadFromXlsx></UploadFromXlsx>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" class="d-flex justify-center">
                    <v-table style="width: 100%">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>DNI</th>
                                <th>Celular</th>
                                <th>Estado de envio</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody v-for="item in users" :key="item.dni">
                            <tr>
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.dni }}</td>
                                <td>{{ item.celular }}</td>
                                <td>
                                    <v-chip color="green">
                                    Enviado a WhatsApp
                                    </v-chip>
                                </td>
                                <td>
                                    
                                    <QrModal :url="'https://heroku.app/'+item.dni" :nombre="item.nombre" :tel="item.celular"></QrModal>
                                </td>
                            </tr>
                        </tbody>
                        </v-table>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
  </template>
  
  <script setup lang="ts">
  import { ref, watch } from 'vue';
  import UploadFromXlsx from './UploadFromXlsx.vue';
  import QrModal from './QrModal.vue';
  import { useUserStore } from '@/store/Users';
  const store = useUserStore()

  // Definir la interfaz de los usuarios
  interface User {
    nombre: string;
    dni: string;
    celular: string;
  }
  
  // Estado de los usuarios
  const users = ref<any[]>([]);

  
  // Función para agregar un nuevo usuario
  const updateUsers = ()=>{
    const userArr = store.getUsers;
    users.value = userArr;
    }

    watch(() => store.getUsers, () => {
        updateUsers()
    }, { immediate: true })
  </script>
  
  <style scoped>
  .v-data-table th, .v-data-table td {
    word-wrap: break-word;
    max-width: 200px;
  }
  </style>
  