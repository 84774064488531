<template>
  <main class="py-12 home-view" fluid>
    <div class="bg-gray"></div>
    <!-- <div class="jhoel-maritza-text">
      <img class="jm-text-image" :src="jmRext">
      <span class="date-text">28/12/2024</span>
    </div> -->
    <v-container >
      <ListUsers style="margin-top: 1rem;"></ListUsers>
    </v-container>
  </main>
  
</template>

<script setup lang="ts">
// import jmRext from '@/assets/images/jm--text.svg'
import ListUsers from '@/components/users/ListUsers.vue'

import { useRouter } from 'vue-router';
const router = useRouter();
const goInvitation = ():void => {
      router.push('/invitacion');
}
</script>
<style scoped lang="scss">


</style>