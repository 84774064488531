<template>
    <v-app> 
      
      <v-app-bar :elevation="2">
        <template v-slot:prepend>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </template>

        <v-app-bar-title>Qr - Gen</v-app-bar-title>
      </v-app-bar>
      <div>
          <slot></slot>
      </div>
  
      <v-footer name="footer" color="#1e1e1e">
        <div class="py-2 text-center w-100">
          {{ new Date().getFullYear() }} — <strong>QR - Generator</strong>
        </div>
      </v-footer>  
    </v-app>
</template>

<script setup lang="ts">
    import { ref } from 'vue'
    import { useTheme } from 'vuetify'
    import { useRouter } from 'vue-router';
    // import logo from '@/assets/images/JM - svg.svg'

    // Estado reactivo para controlar el tema, con tipado booleano
    const isDark = ref<boolean>(false)
    const router = useRouter();

    let textColorMenu = ref<string>("white");

    // Obtener el sistema de temas de Vuetify con su tipo
    const { global } = useTheme()

    // Función para alternar entre los temas claro y oscuro
    const toggleTheme = (): void => {
      isDark.value = !isDark.value
      global.name.value = isDark.value ? 'dark' : 'light'
    }
    const goHome = ():void => {
      router.push('/');
    }
</script>

  
<style scoped>
/* Navbar General */
.main-navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: all 0.3s;
}

.scrolled{
  background: rgba(255, 255, 255, 0.2); /* Glass background */
  /* border-radius: 15px; */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25); /* Glass shadow */
  backdrop-filter: blur(10px); /* Blur effect */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Subtle border */
}
/* Logo Styling */
.logo-navbar {
  width: 50px;
  height: 50px;
  object-fit: contain;
  cursor: pointer;
}

.logo-navbar img {
  width: 100%;
  height: 100%;
}

/* Menu Items */
.menu-items {
  display: flex;
  gap: 1.5rem;
}

.menu-items a {
  color: #000;
  font-family: 'titlingLight';
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.main-container {
  background-color: red;
  width: 100%;
  height: 100vh;
}
</style>
 