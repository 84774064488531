import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: (): { users: Array<any> } => ({
    users: [],
  }),
  getters: {
    getUsers: state => {
        return state.users
    }
  },
  actions: {
    setUsers(users:Array<any>){
      this.users = users;
    },
    cleanScoreSeller(){
      this.users = []
    }
  },
})
