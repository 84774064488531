<template>
    <v-card class="pa-4" outlined flat>
      <v-card-title class="text-h6" v-if="text">
        Código QR para: {{ nombre }} <br> {{ text }}
      </v-card-title>
  
      <!-- Previsualización de la imagen combinada QR + Marco -->
      <v-card-subtitle v-if="combinedImageUrl">
        <v-img :src="combinedImageUrl" contain></v-img>
      </v-card-subtitle>
  
      <v-alert v-if="!text" type="warning" class="mt-4">
        Por favor, ingrese un texto para generar el código QR.
      </v-alert>
  
      <v-alert v-if="uploadedUrl" type="success" class="mt-4">
        Imagen combinada subida con éxito. <br>
        <a :href="uploadedUrl" target="_blank">Ver imagen</a>
      </v-alert>
  
      <v-btn @click="uploadAndGenerateLink" color="primary" v-if="combinedImageUrl">
        Subir imagen combinada
      </v-btn>
    </v-card>
  </template>
  
  <script setup lang="ts">
  import { defineProps, defineExpose, ref, watch } from 'vue';
  import QRCode from 'qrcode';
  import { getStorage, ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage';
  import axios from 'axios';
  
  // Definir las props
  const props = defineProps({
    text: { type: String, required: true },
    nombre: { type: String, required: true },
    tel: { type: Number, required: true },
  });
  
  // Definir el estado reactivo
  const qrCodeUrl = ref<string>('');
  const combinedImageUrl = ref<string | null>(null); // URL de la imagen combinada
  const uploadedUrl = ref<string | null>(null);
  
  // Función para cargar la imagen del marco desde los assets
  const loadFrameImage = async () => {
    const frame = new Image();
    frame.src = require('@/assets/marco.jpg'); // Asegúrate de que la ruta del marco sea correcta
    return new Promise<HTMLImageElement>((resolve) => {
      frame.onload = () => resolve(frame);
    });
  };
  
  // Función para cargar cualquier imagen desde una URL (incluyendo base64)
  const loadImage = (src: string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);  // Resolvemos la promesa cuando la imagen se carga
      img.onerror = (err) => reject(new Error("Error al cargar la imagen: " + err));  // Rechazamos si ocurre un error
      img.src = src;  // Asignamos la URL al src
    });
  };
  
  // Función para combinar QR + marco en un canvas
  const combineImages = async () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx || !qrCodeUrl.value) {
      console.error('QR code URL is not available!');
      return;
    }
  
    // Crear el objeto Image para el QR y asegurarse de que esté cargado
    const qrImage = await loadImage(qrCodeUrl.value); // Esperar a que la imagen QR esté completamente cargada
    const frameImage = await loadFrameImage(); // Cargar la imagen del marco
  
    // Ajustar el tamaño del canvas según el tamaño de la imagen del marco
    canvas.width = frameImage.width;
    canvas.height = frameImage.height;
  
    // Dibujar el marco en el canvas
    ctx.drawImage(frameImage, 0, 0);
  
    // Ajustar el tamaño del QR (centrado)
    const qrSize = Math.min(frameImage.width / 2, frameImage.height / 2); // El QR ocupará el 50% del marco
    const qrX = (frameImage.width - qrSize) / 2; // Centrado horizontal
    const qrY = (frameImage.height - qrSize) / 2; // Centrado vertical
  
    // Dibujar el QR centrado sobre el marco
    ctx.drawImage(qrImage, qrX, qrY, qrSize, qrSize);
  
    // Obtener la URL de datos (base64) de la imagen combinada
    combinedImageUrl.value = canvas.toDataURL('image/png');
  };
  
  // Función para generar el código QR
  const generateQRCode = (text: string) => {
    QRCode.toDataURL(text, (err: any, url: any) => {
      if (err) {
        console.error('Error generando el QR:', err);
      } else {
        qrCodeUrl.value = url;
        combineImages(); // Generar la imagen combinada cuando el QR se haya generado
      }
    });
  };
  
  // Observador para generar el código QR cada vez que la prop 'text' cambie
  watch(
    () => props.text,
    (newText) => {
      if (newText) {
        generateQRCode(newText);
      }
    },
    { immediate: true }
  );
  
  // Función para subir la imagen a Cloudinary y obtener la URL
const uploadToCloudinary = async (imageDataUrl: string) => {
  try {
    // Configura la URL de la API de Cloudinary
    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUD_NAME}/image/upload`;

    // Prepara el payload que incluye la imagen base64 y otros parámetros
    console.log(process.env.VUE_APP_UPLOAD_PRESET)
    const formData = new FormData();
    formData.append('file', imageDataUrl.split(',')[1]); // Asegúrate de quitar la cabecera base64
    console.log('file', imageDataUrl.split(',')[1])
    formData.append('upload_preset', process.env.VUE_APP_UPLOAD_PRESET); // Asegúrate de tener un preset de carga
    formData.append('api_key', process.env.VUE_APP_CLAUD_API_KEY); // Asegúrate de tener un preset de carga

    // Realiza la solicitud POST a la API de Cloudinary
    const response = await axios.post(cloudinaryUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Obtiene la URL de la imagen subida desde la respuesta de Cloudinary
    const imageUrl = response.data.secure_url;

    uploadedUrl.value = imageUrl; // Guardar la URL de la imagen subida

    console.log('Imagen subida a Cloudinary:', imageUrl);
    return imageUrl;
  } catch (error) {
    console.error('Error al subir la imagen a Cloudinary:', error);
    return null;
  }
};

  
  // Función para generar el QR, combinarlo con el marco y subirlo a Cloudinary
  const uploadAndGenerateLink = async () => {
    if (combinedImageUrl.value) {
      const urlForWs = await uploadToCloudinary(combinedImageUrl.value);
      if (urlForWs) {
        uploadedUrl.value = urlForWs;
      }
    }
  };
  
  defineExpose({
    uploadAndGenerateLink,
  });
  </script>
  
  <style scoped>
  .v-img {
    max-width: 100%;
    height: auto;
  }
  </style>
  