import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import firestorePlugin from './configs/FirebasePlugin';
import firebasePlugin from './configs/Firebase';
// custom global styles

loadFonts()
const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(vuetify)
// app.use(appFirebase)
// app.use(authPlugin)
app.use(firebasePlugin)
app.use(firestorePlugin)

app.mount('#app')
