// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';



const firebaseConfig = {
  apiKey:process.env.VUE_APP_API_KEY_FIREBASE,
  authDomain:process.env.VUE_APP_AUTH_DOMAIN,
  projectId:process.env.VUE_APP_PROJECT_ID,
  storageBucket:process.env.VUE_APP_STORAGE_BK,
  messagingSenderId:process.env.VUE_APP_MESSAGE_SENDERING_ID,
  appId:process.env.VUE_APP_APP_ID
};

// Initialize Firebase
const firebasePlugin = (app:any) => {
    const firebaseApp = initializeApp(firebaseConfig);
    app.config.globalProperties.$firebaseApp = firebaseApp;
  };
  
export default firebasePlugin;